<template>
  <div>
    <v-row>
      <v-col class="py-0">
        <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
    </v-row>
    <br>
    <br>
    <v-row>
      <v-col>
        <v-btn
          v-if="$can('create', 'usuario')"
          color="success"
          @click="showDialog()"
          >NOVO</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col :class="{ shorten: $vuetify.breakpoint.lgAndUp }">
        <v-data-table
          :headers="grid.headers"
          :items="filteredItems"
          :loading="grid.loading"
          loading-text="Carregando... aguarde"
          locale="pt"
          class="elevation-1"
        >
          <template v-slot:item.nome="{ item }">
            <span>{{ item.nome }}</span>
          </template>

          <template v-slot:item.descricao="{ item }">
            <span>{{ item.descricao }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <div>
              <v-icon
                v-if="$can('edit', 'usuario')"
                color="green"
                class="mr-3"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="$can('delete', 'usuario')"
                color="red"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <v-navigation-drawer
          v-if="$vuetify.breakpoint.lgAndUp || mobileFilter"
          v-model="mobileFilter"
          :permanent="$vuetify.breakpoint.lgAndUp"
          :absolute="$vuetify.breakpoint.lgAndUp"
          :fixed="$vuetify.breakpoint.mdAndDown"
          right
          class="elevation-1"
        >
          <v-card elevation="0" class="pa-4">
            <v-card-title class="grey--text text--darken-2"
              >Filtros</v-card-title
            >
            <v-text-field
              v-model="grid.filters.nome"
              label="Perfil"
              dense
              solo
            ></v-text-field>
            <v-text-field
              v-model="grid.filters.descricao"
              label="Descrição"
              dense
              solo
            ></v-text-field>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" text @click="filter"> Filtrar </v-btn>
            </v-card-actions>
          </v-card>
        </v-navigation-drawer>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.mdAndDown" class="mt-13">
      <v-col>
        <v-btn
          color="light-blue darken-3"
          fixed
          dark
          bottom
          right
          fab
          @click="toggleMobileFilterVisibility()"
        >
          <v-badge color="red" dot :value="grid.filtered">
            <v-icon>mdi-filter</v-icon>
          </v-badge>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Dialog -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="isFormValid" autocomplete="off">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Nome perfil"
                      v-model="defaultItem.nome"
                      :rules="rules.nome"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Descrição perfil"
                      v-model="defaultItem.descricao"
                      :rules="rules.descricao"
                      required
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <div
                      v-for="perm in defaultItem.permissoes"
                      :key="perm.permissaoId"
                    >
                      <v-checkbox
                        :rules="rules.permissoes"
                        v-model="perm.marcada"
                        :label="perm.nome"
                      >
                      </v-checkbox>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="handleItem">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import pageLoadingMixin from "mixins/page-loading.mixin";
import Validations from "services/validations/validations.js";
import notificationMixin from "mixins/notification.mixin";
import configurations from "~/commons/configurations";
import { AUTH_NAMESPACE, USER, MENU } from "store/modules/auth";

const { mapGetters: postLoginGetters } = createNamespacedHelpers(
  AUTH_NAMESPACE
);

export default {
  name: "ManagerUsersProfiles",
  mixins: [pageLoadingMixin, notificationMixin],
  beforeMount() {
    this.showPageLoading();
    this.verificarMenuDoUsuario();
  },
  created() {
    this.getPermissionsList();
    this.getGroupList();
  },
  mounted() {
    if (
      this.permissoes == [] ||
      this.permissoes == undefined ||
      this.permissoes.length == 0
    ) {
      this.getPermissionsList();
      this.getGroupList();
    }
  },
  data: () => ({
    isFormValid: undefined,
    breadcrumbs: [
      {
        text: "Início",
        to: "/admin",
      },
      {
        text: "Perfil",
        to: "/admin/perfil",
      },
    ],
    isMobileFilterVisible: false,
    mobileFilter: null,
    grid: {
      loading: false,
      filtered: false,
      filters: {
        nome: "",
        descricao: "",
      },
      headers: [
        { text: "Perfil", value: "nome", align: "start", sortable: false },
        {
          text: "Descrição",
          value: "descricao",
          align: "start",
          sortable: false,
        },
        { text: "Ações", value: "actions", align: "center", sortable: false },
      ],
      data: [],
    },
    permissoes: [],
    escolhas: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],
    editedIndex: -1,
    indexGroup: -1,
    cleanItem: {
      id: 0,
      nome: "",
      descricao: "",
      icone: "",
      permissoes: [],
      ativo: undefined,
      nomeDeUsuario: "",
    },
    defaultItem: {
      id: 0,
      nome: "",
      descricao: "",
      icone: "",
      permissoes: [],
      ativo: undefined,
      nomeDeUsuario: "",
    },
    dialog: false,
  }),
  methods: {
    getPermissionsList() {
      this.$api()
        .get(configurations.api.permissions.getPermissionsListUri)
        .then(({ data }) => {
          this.permissoes = data.resposta.permissoes;
          this.defaultItem.permissoes = data.resposta.permissoes;
          this.cleanItem.permissoes = data.resposta.permissoes;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning(
              "Não conseguimos consultar a lista de permissões."
            );
          }
        });
    },
    getGroupList() {
      this.grid.loading = true;
      this.$api()
        .get(configurations.api.groups.getGroupListUri)
        .then(({ data }) => {
          data.resposta.forEach((grupo) => {
            var arrayPermissoes = JSON.parse(JSON.stringify(this.permissoes));
            grupo.permissoes.forEach((gp) => {
              var index = arrayPermissoes.findIndex(
                (i) => i.permissaoId === gp.permissaoId
              );
              arrayPermissoes[index].marcada = gp.marcada;
              arrayPermissoes[index].permissaoGrupoId = gp.permissaoGrupoId;
            });
            grupo.permissoes = Object.assign([], arrayPermissoes);
          });
          this.grid.data = data.resposta;
          this.hidePageLoading();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          }
        })
        .catch(() => {
          this.grid.loading = false;
          this.hidePageLoading();
        });
    },
    addGroup() {
      delete this.defaultItem.id;
      this.defaultItem.nomeDeUsuario = this.user.username;
      if (this.editedIndex === -1) {
        this.defaultItem.permissoes = this.permissoes;
      }
      this.$api()
        .post(configurations.api.groups.getGroupRegisterUri, this.defaultItem)
        .then(({ data }) => {
          if (data && data.grupo) {
            var arrayPermissoes = JSON.parse(JSON.stringify(this.permissoes));
            data.grupo.permissoes.forEach((pg) => {
              var index = arrayPermissoes.findIndex(
                (i) => i.permissaoId === pg.permissaoId
              );
              arrayPermissoes[index].marcada = pg.marcada;
              arrayPermissoes[index].permissaoGrupoId = pg.permissaoGrupoId;
            });
            data.grupo.permissoes = Object.assign([], arrayPermissoes);

            this.grid.data.unshift(data.grupo);
            this.notifySuccess("Perfil cadastrado com sucesso.");
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos cadastrar o perfil.");
          }
        });
    },
    showDialog() {
      this.defaultItem = Object.assign({}, this.cleanItem);
      this.dialog = true;
    },
    handleItem() {
      this.defaultItem.nomeDeUsuario = this.user.username;
      this.$refs.form.validate();
      if (this.isFormValid) {
        if (this.editedIndex > -1) {
          this.$api()
            .put(configurations.api.groups.getGroupUpdateUri, this.defaultItem)
            .then(({ data }) => {
              if (data && data.grupo) {
                this.processarEdicaoDeItem(data);
              }
            })
            .catch(({ response }) => {
              this.exibirMensagemDeRetorno(
                response,
                "Não conseguimos atualizar o perfil."
              );
            });
        } else {
          this.addGroup();
        }
        this.close();
      } else this.notifyWarning("Preencha todos os campos obrigatórios");
    },
    processarEdicaoDeItem(data) {
      var arrayPermissoes = JSON.parse(JSON.stringify(this.permissoes));
      data.grupo.permissoes.forEach((pg) => {
        var index = arrayPermissoes.findIndex(
          (i) => i.permissaoId === pg.permissaoId
        );
        if (index > -1) {
          arrayPermissoes[index].marcada = pg.marcada;
          arrayPermissoes[index].permissaoGrupoId = pg.permissaoGrupoId;
        }
      });
      data.grupo.permissoes = Object.assign([], arrayPermissoes);
      Object.assign(this.grid.data[this.indexGroup], data.grupo);
      this.defaultItem = Object.assign({}, this.cleanItem);
      this.notifySuccess("Informações atualizadas com sucesso.");
    },
    exibirMensagemDeRetorno(response, msg) {
      if (response && response.data.mensagem) {
        this.notifyWarning(response.data.mensagem);
      } else {
        this.notifyWarning(msg);
      }
    },
    editItem(item) {
      this.editedIndex = this.grid.data.indexOf(item);
      this.indexGroup = this.editedIndex;
      this.defaultItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.grid.data.indexOf(item);
      var result = confirm("Tem certeza de que deseja excluir este perfil?");
      if (result) {
        this.$api()
          .post(configurations.api.groups.getDisableGroupUri, {
            id: item.id,
            nomeDeUsuario: this.user.username,
          })
          .then(({ data }) => {
            if (data && data.sucesso) {
              this.grid.data.splice(index, 1);
              this.notifySuccess("Perfil deletado com sucesso.");
            }
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.notifyWarning(response.data.mensagem);
            } else {
              this.notifyWarning("Não conseguimos deletar o perfil.");
            }
          });
      }
    },
    close() {
      this.dialog = false;
      this.editedIndex = -1;
    },
    toggleMobileFilterVisibility() {
      this.mobileFilter = !this.mobileFilter;
    },
    filter() {
      if (this.grid.filters.perfil || this.grid.filters.administrador) {
        // Filtrar
        this.grid.filtered = true;
      } else {
        // Limpar filtro
        this.grid.filtered = false;
      }
      this.mobileFilter = false;
    },
    verificarMenuDoUsuario() {
      if (this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`].filter(m => m.url === "/admin/perfil").length === 0) {
        this.$router.push({ path: "/login" });
      }
    }
  },
  computed: {
    ...postLoginGetters([USER]),
    rules() {
      return {
        nome: [
          (value) => Validations.required(value),
          (value) => Validations.min(value, 4),
        ],
        descricao: [
          (value) => Validations.required(value),
          (value) => Validations.min(value, 5),
        ],
      };
    },
    filteredItems() {
      return this.grid.data.filter((i) => {
        return (
          i.nome.toLowerCase().includes(this.grid.filters.nome.toLowerCase()) &&
          i.descricao
            .toLowerCase()
            .includes(this.grid.filters.descricao.toLowerCase())
        );
      });
    },
    formTitle() {
      return this.editedIndex === -1 ? "Cadastrar perfil" : "Editar perfil";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  .col-email {
    max-width: 200px;
  }

  .col-address {
    max-width: 200px;
  }
}
.bt {
  border: 0;
  padding: 10px;
  width: 300px;
  height: 0;
  display: inline-block;
  margin: 30px;
  cursor: pointer;
  border-radius: 4px;
}
.bt2 {
    text-align: center;
}

.shorten {
  max-width: calc(100% - 260px);
}
</style>
